// Generated by Framer (4431e6b)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Ticker from "https://framerusercontent.com/modules/B2xAlJLcN0gOnt11mSPw/nFAy8p4fOASsyhPbo192/Ticker.js";
const TickerFonts = getFonts(Ticker);

const cycleOrder = ["bJnn13dIf"];

const variantClassNames = {bJnn13dIf: "framer-v-1bxiqf1"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; tap?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "bJnn13dIf", tap: F_jzf4s8Q, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "bJnn13dIf", transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1i23i8b = activeVariantCallback(async (...args) => {
if (F_jzf4s8Q) {
const res = await F_jzf4s8Q(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-CuQif", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1bxiqf1", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"bJnn13dIf"} onTap={onTap1i23i8b} ref={ref} style={{backgroundColor: "rgb(255, 245, 0)", ...style}}><motion.div className={"framer-1cph8a8-container"} layoutDependency={layoutDependency} layoutId={"V4zR3Pfqo-container"}><Ticker alignment={"center"} direction={"left"} fadeOptions={{fadeAlpha: 0, fadeContent: true, fadeInset: 0, fadeWidth: 0, overflow: false}} gap={0} height={"100%"} hoverFactor={1} id={"V4zR3Pfqo"} layoutId={"V4zR3Pfqo"} padding={0} paddingBottom={0} paddingLeft={0} paddingPerSide={false} paddingRight={0} paddingTop={0} sizingOptions={{heightType: true, widthType: true}} slots={[]} speed={40} style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-CuQif [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-CuQif .framer-ln5gt1 { display: block; }", ".framer-CuQif .framer-1bxiqf1 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 40px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 1200px; }", ".framer-CuQif .framer-1cph8a8-container { flex: none; height: 200px; position: relative; width: 100%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-CuQif .framer-1bxiqf1 { gap: 0px; } .framer-CuQif .framer-1bxiqf1 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-CuQif .framer-1bxiqf1 > :first-child { margin-left: 0px; } .framer-CuQif .framer-1bxiqf1 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"F_jzf4s8Q":"tap"}
 */
const FramerBgt06uGOL: React.ComponentType<Props> = withCSS(Component, css, "framer-CuQif") as typeof Component;
export default FramerBgt06uGOL;

FramerBgt06uGOL.displayName = "Marquee";

FramerBgt06uGOL.defaultProps = {height: 40, width: 1200};

addPropertyControls(FramerBgt06uGOL, {F_jzf4s8Q: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerBgt06uGOL, [...TickerFonts])